<template>
  <div class="nav">
    <img src="../assets/images/nav/logo2.png" alt="">
    <ul>
      <li v-for="(item, index) in navList" :key="index"
        :class="(item.chooseNav && isHome) || pageName == item.chooseValue ? 'choose-nav' : ''"
        @click="clickHandle(item.chooseValue)">{{ item.name }}</li>
    </ul>
    <a href="https://oa.koobietech.com:7100/" target="_blank">登录OA</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chooseNav: '',
      navList: [{
        name: '首页',
        chooseValue: 'home',
        chooseNav: true
      }, {
        name: '产品',
        chooseValue: 'product',
        chooseNav: false
      }, {
        name: '解决方案',
        chooseValue: 'solve',
        chooseNav: false
      },
      //  {
      //   name: '应用案例',
      //   chooseValue: 'application',
      //   chooseNav: false
      // },
       {
        name: '核心技术',
        chooseValue: 'core',
        chooseNav: false
      }, {
        name: '关于我们',
        chooseValue: 'about',
        chooseNav: false
      }, {
        name: '加入我们',
        chooseValue: 'join',
        chooseNav: false
      }]
    }
  },
  watch: {
    type(newval) {
      this.navList.forEach((val) => {
        val.chooseNav = false
        if (val.chooseValue == newval) {
          val.chooseNav = true
        }
      })
    }
  },
  props: ['isHome', 'type', 'pageName'],
  methods: {
    clickHandle(type) {
      if (!this.isHome) {
        if (type == 'about' || type == 'join') {
          let _location = window.location.href
          if (type == 'about') {
            if (_location.includes('about')) {
              return false
            } else {
              this.$router.push('/about')
            }
          } else if (type == 'join') {
            if (_location.includes('join')) {
              return false
            } else {
              this.$router.push('/join')
            }
          }
        } else {
          window.localStorage.setItem('navType', type)
          this.$router.push('/')
        }
      } else {
        this.navList.forEach((val) => {
          val.chooseNav = false
          if (val.chooseValue == type) {
            val.chooseNav = true
          }
        })
        this.$emit('getType', type)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nav {
  width: 100vw;
  height: 80px;
  background: #ffffff;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #141414;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  img {
    width: 125px;
    height: 42px;
    margin-left: 74px;
  }

  ul {
    display: flex;
    flex: 1;
    justify-content: center;

    li {
      margin-right: 70px;
      cursor: pointer;
      user-select: none;
      font-weight: 400;
    }
  }

  ul li:nth-child(7) {
    margin-right: 0;
  }

  .choose-nav {
    font-weight: 800;
  }
  a{
    margin-right: 28px;
  }
}
</style>