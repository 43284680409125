<template>
  <div class="smart-farm">
    <!-- 导航栏 -->
    <Nav :isHome="false"></Nav>
    <CommonNav pageName="互联网产品"></CommonNav>
    <section>
      <img class="smart-farm-bg" src="../../assets/images/computer/bg.png" alt="">
    </section>
    <section class="smart-farm-container">
      <div class="container-list">
        <img src="../../assets/images/computer/canteen.png" alt="">
        <div>
          <div class="container-text-style">
            <h4>智慧食堂</h4>
            <p class="smart-farm-article">
              智慧食堂结合高校食堂实际运营环境，通过物联网、大数据、人脸识别等技术，实现降低人工、食材成本，提升食堂面积利用率、客户满意度，便捷化科学管理、提高产品和服务的优质体验度、食品安全卫生、责任人实时监管等效果，打造智慧食堂一站式整体解决方案。采用智能结算一体机硬件+智慧食堂管理云平台软件餐厅现场使用智能结算一体机，在就餐人进入打餐区前进行自助刷脸扣费，扣费成功后进入打餐区进行打餐，现场搭配工作人员协助监管个别就餐人逃单、刷一人脸多人就餐等不文明行为，对其行为进行劝说及制止。打餐完成后通过打餐区出口（双向，含二次打餐入口）进入就餐区，如需二次打餐可通过二次打餐入口（双向，含打餐区出口）再次进入打餐区，就餐完成后将餐具放置餐具回收区，完成本次就餐。
            </p>
          </div>

        </div>
      </div>
      <div class="container-list">
        <img src="../../assets/images/computer/community.png" alt="">
        <div>
          <div class="container-text-style">
            <h4>智慧社区</h4>
            <p class="smart-farm-article">
              智慧社区是指通过利用各种智能技术和方式，整合社区现有的各类服务资源，为社区群众提供政务、商务、娱乐、教育、医护及生活互助等多种便捷服务的模式。通过大数据、可视化和信息化技术，基于物联网、有线通信和无线通信多种手段，实现社区网格化管理，提升社区管理精度，减少无效化工作内容，拉高社区管理效果，降低社区安全风险。通过智能楼宇、智能家居、智能监控、智慧医疗等多种手段，全面提升社区内人民居住幸福感，有效推动经济转型，促进现代服务业发展。
            </p>
          </div>

        </div>
      </div>
      <div class="container-listSecond">
        <img src="../../assets/images/computer/food.png" alt="">
        <div>
          <div class="container-text-style">
            <h4>智能粮食烘干塔</h4>
            <p class="smart-farm-article">
              “中国人的饭碗要端在自己手里”。智能粮食烘干塔，利用物联网、人工智能技术，改善目前粮食烘干塔的效率、质量、成本等问题。<br />现有问题VS产品优势<br />问题：人工定时抽测水分、人工粗调电机、温度，测量存在时效性问题，调参存在滞后性问题。<br />优势：基于AI的在线水分测量技术，对电参数与实际水分值进行拟合标定，实现传送带粮食水分在线监测；基于原有电气控制柜通过监控、旁路、部分接管等方式，实现智能化，数据集训练识别模型和OCR模型，实现传统仪表读数的采集、联网、反馈控制。<br />现有问题VS产品优势<br />问题：塔、锅炉、燃料、运输等人员、设备、物料调度混乱，成本管理不清晰，人员产出和质量、无考核，发展遇瓶颈。孤岛效应，各塔数据（用煤、用电、产能、质量等）不流通、无法指导分析决策。<br />优势：调研农场、烘干企业、农民、塔主、塔长、锅炉工等单位及人员，整理了烘干业务20余项，本项目整合ERP、OA、云等技术和产品，实现烘干塔业务云，支持收出粮管理、财务管理、人员管理、提升管理效率，增加收益。
            </p>
          </div>
        </div>
      </div>
      <div class="container-listSecond">
        <img src="../../assets/images/computer/water.png" alt="">
        <div>
          <div class="container-text-style">
            <h4>智慧水位识别</h4>
            <p class="smart-farm-article">
              智慧水位识别产品，融合了人工智能、大数据、物联网等技术，基于 AIoT 架构开发，用先进技术，解放生产力，为水利、环保、市政、农业灌溉等行业提供水位数据 +
              历史影像双重信息的无人值守解决方案。<br />现有问题VS产品优势<br />问题：水文观测点分散，现场环境复杂，目前依赖人工定期现场巡检、现场观测。存在实时性差、效率低、人员安全风险等问题。<br />优势：使用摄像头+边缘计算+5G技术，采集现场水尺画面，AI分析、网络上传。实现远程巡检、远程观测、实时记录。<br />现有问题VS产品优势<br />问题：观测点、测站、区县、地市、省多级部门衔接不畅、水文数据上报周期长，应急响应速度慢，数据共享、指令下发速度慢。<br />优势：
              现场智能终端：水文测站现场的智慧大脑，负责图像采集与录入，智能控制，决策提醒与执行功能。 微信小程序：观测员与测站站长的千里眼，手机实时查看水尺图像、水尺读数，辅助决策。
              电脑端管理网页：智能管理系统，提供数据看板、数据分析、历史对照、数据上报等功能。三终端协同，共同达成高效观测。
            </p>
          </div>
        </div>
      </div>
      <div class="container-listThird">
        <img src="../../assets/images/computer/animal.png" alt="">
        <div class="container-text">
          <div class="container-text-style">
            <h4>智慧牧场</h4>
            <p class="smart-farm-article">
              将大数据、人工智能、云计算、物联网、移动互联网等技术在养殖场内进行应用，促进数字技术与养殖业发展深度融合并贯穿养殖发展全过程，提高养牧场能级和效率，全面实现家禽家畜养殖数字化转型，激发发展新活力，培育新动能和形成竞争新优势，提升养牧场数字化水平和效益。<br />现有问题VS产品优势<br />问题：牧场、养殖舍环境监测使用传统温湿度表，采集不方便，上报不及时，监测参数少，无历史数据。<br />优势：实时监测温度、湿度、二氧化碳含量、氧气含量、氨气含量、PM2.5等参数。通过自动测量、上传、曲线绘制，快速地感知和洞察猪舍异常情况。饲养员、技术员、厂长可结合自身经验，及时了解各猪舍的环境情况，及时调整，保证最大成活率和经济的育肥速度，提高效益。<br />现有问题VS产品优势<br />问题：养殖设备仅支持手动开关，无法与环境联动；设备运行状态无法监测。优势：接入风机、湿帘等降温设备，设备运行档位及时间可依据猪舍目标温度灵活配置。配置与监测一体构成控制闭环，及时精准调控猪舍环境。远程配置参数和远程升级程序。无须现场操作，有效降低生物安全风险。<br />现有问题VS产品优势<br />问题：无系统化管理，个体记录混乱，繁殖数据不详细，销售营收记录不清晰，关键指标未量化，养殖企业发展遇瓶颈。<br />优势：通过智能耳标、养殖舍编号、产床编号，配合养殖管理云平台，实现种质档案、繁殖管理、生产管理、免疫管理、销售管理等全面全方位的管理。
            </p>
          </div>
        </div>
      </div>
      <div class="container-listThird">
        <img src="../../assets/images/computer/hrss.png" alt="">
        <div class="container-text">
          <div class="container-text-style">
            <h4>智慧人社一体机</h4>
            <div class="article-special">
              “智慧人社一体机“赋能数字化业务办理场所，营造高效办理效率理念，实现“用户+信息+目标”全景化信息智能操作；以业务场景为导向，提升业务应用场景化的有用性。以技术为驱动，提升业务智能应用的先进性。以效率为宗旨，提升产品应用易用性。
            </div>
            <h4 class="smart-farm-clerk">产品优势</h4>
            <div class="smart-farm-script">
              1.<strong>服务便利</strong>，居民可在附近银行/办事大厅进行机器自助操作，无需排队进行窗口服务；
            </div>
            <div class="smart-farm-script">
              2.<strong>操作便捷</strong>，居民可根据自己所需办理业务进行语言/触屏操作直接办理；
            </div>
            <div class="smart-farm-script">
              3.<strong>服务变通</strong>，各银行均可部署，用户可随地进行查询到银行自行办理；
            </div>
            <div class="smart-farm-script">
              4.<strong>人机交互</strong>，打破以往传统设备单一的用户操作习惯，打造人+设备互通、互利操作模式；
            </div>
            <div class="smart-farm-script">
              5.<strong>智能识别</strong>，可帮助正常用户、聋哑用户等群体进行专业化操作识别体系的搭建。
            </div>
          </div>
        </div>
      </div>
      <div class="container-list">
        <img src="../../assets/images/computer/pig.png" alt="">
        <div>
          <div class="container-text-style">
            <h4>现代化猪场分析系统</h4>
            <p class="smart-farm-article">
              现代猪场生态分析系统，是一个基于Web大数据的猪场养殖分析知识系统，能够支撑猪场实现母猪、公猪、仔猪、育肥等各阶段猪数量的合理化分配，饲料用量估算、兽药用量估算。提高养殖业经济指标的合理规划。<br/>产品包括：猪生长阶段详细表、采食替代换算表、屠宰价格测算、经济利益测算、饲料测算、母猪成本测算等模块。
            </p>
          </div>

        </div>
      </div>
      <div class="container-list">
        <img src="../../assets/images/computer/radar.png" alt="">
        <div>
          <div class="container-text-style">
            <h4>绩效雷达</h4>
            <p class="smart-farm-article">
              绩效雷达项目主要用于解决用户无法准确获取所需地点位置信息数据,基于客户提供部分数据,对该部分数据获取精确位置信息,通过某一指定圆心点为中心获取该点不同半径范围内百度侧数据,对不同数据源数据进行差异化匹配处理,获知已匹配、未匹配数据分类,同时经过内部加权算法计算出相应数据点位对应评分,根据评分进行重新排序推荐,同时对处理后的数据在由我们自定义的百度地图上完成展示,该地图视图上过滤支持关键字搜索、范围搜索、状态搜索，同时可以切换到列表视图使用同样的功能,并且用户可在该视图下对目标数据进行二次标记处理进行提交修改申请工作，提交申请时可以现场拍照并返回附带水印图片进行上传用以辅助审核，完成审核后完成对目标数据的修改。
            </p>
          </div>
        </div>
      </div>
      <div class="container-listFive">
        <img src="../../assets/images/computer/oa.png" alt="">
        <div>
          <div class="container-text-style">
            <h4>OA办公</h4>
            <p class="smart-farm-article">
              OA是一款基于ThinkPHP6 + Layui + MySql打造的实用的开源的企业办公系统，OA可以简单快速地建立企业级的办公自动化系统。 办公自动化系统是员工及管理者使用频率最高的应用系统，可以极大提高公司的办公效率，我们立志为中小企业提供开源好用的办公自动化系统，帮助企业节省数字化、信息化办公的成本。<br/>系统各功能模块，一目了然，操作简单；通用型的后台权限管理框架，员工的操作记录全覆盖跟踪，紧随潮流、极低门槛、开箱即用。系统集成了系统设置、人事管理、行政管理、消息管理、企业公告、知识库、审批流程设置、办公审批、日常办公、财务管理、客户管理、合同管理、项目管理、任务管理等基础模块。系统方便二次开发，易于功能扩展，代码维护，满足专注业务深度开发的需求。开发人员可以快速基于此系统进行二次开发，免去写一次系统架构的痛苦，帮助开发者高效降低开发的成本，通过二次开发之后可以用来做CRM，ERP，项目管理等企业办公系统。系统后台集成了主流的通用功能，如：登录验证、系统配置、操作日志管理、角色权限、职位职称、功能菜单、模块管理、关键字管理、文件上传、数据备份/还原、基础数据、审批流程、员工管理、消息通知、企业公告、知识文章、办公审批、日常办公、财务管理、客户管理、合同管理、项目管理、任务管理等。
            </p>
          </div>
        </div>
      </div>
      <div class="container-listFive">
        <img src="../../assets/images/computer/postoffice.png" alt="">
        <div>
          <div class="container-text-style">
            <h4>邮贝-数字邮局</h4>
            <p class="smart-farm-article">
              为贯彻落实《关于推进实施国家文化数字化战略的意见》（以下简称“意见”），中国邮政集团有限公司北京市分公司于2月21日在京正式推出首个数字邮品发行平台——邮贝·数字邮局，并正式上线数字邮局小程序。邮贝·数字邮局将首发推出重磅数字邮品——西藏博物馆名誉馆长、中国著名画家叶星生的代表作《人民大会堂西藏厅壁画》（7张）。<br/>为切实践行《意见》要求，改造提升传统动能，培育发展新动能，邮贝·数字邮局积极探索利用数字技术升级传统集邮市场的解决方案。据了解，该平台所发行的产品均由线上数字认证部分与线下实物邮品两部分组成，两者借由区块链技术实现一一对应。将数字资产易使用、有确权的优势，与实物邮品有历史、有收藏价值的优势融合叠加，形成可管、可控且有巨大市场潜力的数字文化艺术全新赛道。<br/>邮贝·数字邮局由人民日报全国党媒联盟链提供区块链技术支持服务。在行业数字化转型大背景下，人民日报全国党媒联盟链将持续为公共文化数字化建设做出新的探索
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/nav.vue'
import CommonNav from '@/components/commonNav.vue'
import Footer from '@/components/footer.vue'

export default {
  data() {
    return {

    }
  },
  components: {
    Nav,
    CommonNav,
    Footer
  },
  beforeDestroy(){
    window.scrollTo(0,0)
  }
}
</script>

<style lang="scss" scoped>
.smart-farm {
  width: 100vw;
  padding-top: 120px;
  background: #f8f8f8;
}

.smart-farm-bg {
  width: 100%;
  height: 100%;
}

.smart-farm-container {
  width: 78.6%;
  max-width: 1509px;
  margin: 72px auto 156px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  img {
    width: 100%;
  }

  .container-list {
    width: 48.4%;
    height: 100%;
    margin-bottom: 69px;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    line-height: 0;

    div {
      height: 540px;
      background: #ffffff;
      overflow: hidden;
    }
  }

  .container-listSecond {
    width: 48.4%;
    height: 100%;
    margin-bottom: 69px;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    line-height: 0;

    div {
      height: 659px;
      background: #ffffff;
      overflow: hidden;
    }
  }

  .container-listThird {
    width: 48.4%;
    height: 100%;
    margin-bottom: 69px;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    line-height: 0;


    .container-text {
      height: 793px;
      background: #ffffff;
      overflow: hidden;

      p {
        height: 80%;
        overflow: hidden;
        overflow-y: scroll;
      }

      .article-special {
        line-height: 1.6;
        margin: 28px auto 0;
        font-size: 18px;
        color: #606060;
      }
    }
  }
  .container-listFive{
    width: 48.4%;
    height: 100%;
    margin-bottom: 69px;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    line-height: 0;
    div {
      height: 680px;
      background: #ffffff;
      overflow: hidden;
    }

  }

  h4 {
    font-size: 30px;
    color: #323232;
    font-weight: 500;
    margin: 53px auto 0;
    line-height: 1;
  }

  .smart-farm-article {
    height: 80%;
    line-height: 1.6;
    margin: 28px auto 0;
    font-size: 18px;
    color: #606060;
    overflow: hidden;
    overflow-y: scroll;
    text-align: justify;
    /*兼容ie*/
    text-justify: distribute-all-lines;
  }

  .container-text-style {
    width: 82.3%;
    height: 100%;
    margin: 0 auto;
  }

  .smart-farm-clerk {
    color: #B81010;
    margin: 30px auto 20px;
    font-size: 18px;
  }

  .smart-farm-script {
    line-height: 1.6;
    color: #606060;
    font-size: 18px;
  }
}
</style>