<template>
  <div class="common-nav">
    <h5 style="cursor: pointer;" @click="getHomePage">首页&nbsp;></h5>
    <h5>&nbsp;{{ pageName }}</h5>
  </div>
</template>

<script>
export default {
  props:['pageName'],
  methods: {
    // 跳转首页
    getHomePage(){
      this.$router.push('/')
    }
  },
  beforeDestroy(){
    window.scrollTo(0,0)
  }
}
</script>

<style lang="scss" scoped>
.common-nav {
  width: 100vw;
  height: 40px;
  background: #F6F6F6;
  display: flex;
  align-items: center;
  color: #444444;
  font-size: 16px;
  padding-left: 72px;
  position: fixed;
  top: 80px;
  left: 0;

  h5 {
    font-weight: 400;
  }
}
</style>